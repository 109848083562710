<template lang="pug">
  .page.page-color
    .widget.widget--screen-size
      img.page-color__cover(
        src="@/assets/img/colors-bg.jpg")
      .page-color__content
        //- img.page-color__decor(
          src="@/assets/illustrations/flower-branch.svg")
        p.page-color__text(v-html="$t('colors.text_1')")
        .page-color__colors
          span.page-color__dot(
            v-for="(item, index) in colors"
            :key="index"
            :style="{ background: item }")
          img.page-color__dot(src="@/assets/img/gold-color.png")
</template>

<script>
export default {
  name: 'Index',

  data: () => ({
    colors: [
      '#FBF9ED',
      '#C9867E',
      '#638A51'
    ]
  })
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_animations.scss';
  @import '../../sass/abstracts/_variables.scss';

  .page-color {

    &__cover {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      position: absolute;
      left: 4rem;
      top: 9.5rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      opacity: 0;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }

    &__decor {
      width: 8.5rem;
      margin-bottom: 1rem;
      filter: grayscale(100%) brightness(200%) contrast(400%);
    }

    &__text {
      text-align: left;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-family: 'History Pro';
      line-height: 2.2rem;
      white-space: nowrap;
      margin-bottom: 1rem;
    }

    &__colors {
      display: flex;
    }

    &__dot {
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 50%;
      opacity: 0;
      animation: fade 1.2s cubic-bezier(0.32, 0, 0.67, 0) forwards;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * .1 + .4}s;
        }
      }

      &:not(:last-child) {
        margin-right: .9rem;
      }
    }
  }
</style>
